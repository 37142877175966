import React from 'react'
import CategoryButton from './categoryButton.js'



const categorySuggestions = ({ Categories }) => {
    return (
        <div class="Categories">
            {Categories.map(Category => (
                <CategoryButton key={Category.id} Data={Category} />
            ))}
        </div>
    )
}

export default categorySuggestions
