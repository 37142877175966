import React, {useContext} from 'react'
import { ReactComponent as ArrowRight } from "../icons/arrow.svg"
import UserContext from '../UserContext'


const Dropdown = ({ closeDropdown, logout }) => {
    
    const DropdownItem = ({ leftIcon, iconRight, content, onClick }) =>{
        return(
        <div className="menu-item">
            <span className="icon-button">{ leftIcon }</span>

            <p className="dropdown-content">{ content }</p>

            <span className="icon-right">{ iconRight }</span>
        </div>)
    }
    return (
            <div className="dropdown">
                <DropdownItem content="Your Account"/>
                <DropdownItem content="Settings"/>
                <DropdownItem content="Dark Mode"/>
                <DropdownItem content="Language" iconRight={ <ArrowRight className="svg-icon" /> } />
                <DropdownItem content="Feedback" />
                <a onClick={() => logout()} href="#"><DropdownItem content="Log out"/></a>
                <DropdownItem content="Help" />
            </div>
    )
}

export default Dropdown
