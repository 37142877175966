import React, { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UserContext from '../UserContext'
import Cookie from 'js-cookie'
import WrireLogo from '../icons/logo.svg'
import '../style/css/register-login.css'
import InputForm from '../components/input-form'


function Login() {    
    const {user, setUser} = useContext(UserContext)
    const history = useHistory();
    const [UserData, setUserData] = useState({username: "", password: ""})
    const [LoginDataFalse, setLoginDataFalse] = useState(false) 

    const handleUser = (userToken) => { // get all information of user
        const authData = "Token " + userToken['token']
        console.log(userToken)
    
        fetch('http://localhost:8000/api/me', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                "Authorization": authData
            },
            body: ""
            }).then((response) =>  response.json().then((text)=>{
              console.log(text)
              setUser(text);;
        }))
      }
    

    const submitHandler = e => {
        e.preventDefault();
        setLoginDataFalse(false)

        const data = new URLSearchParams(UserData).toString() // .json => string
        console.log(data)
        fetch('http://localhost:8000/api/api-token-auth/', { // fetch data from backend server
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: data
        }).then((response) => response.json().then((userToken)=>{
            const userTokenString = new URLSearchParams(userToken).toString()
            console.log(userTokenString)
            setLoginDataFalse(userTokenString.indexOf("non_field_errors") !== -1)
            if (LoginDataFalse === false){
                handleUser(userToken)
                Cookie.set("UserToken", userToken["token"], {expires: 1})
                history.push('/home');
            }
            
        }))
    }
    return (
        <div className='login-side'>
            <div className="register-form">
                <div className='container-center'>
                    <img src={WrireLogo} className="wrire-logo" />

                    <h1>Log in</h1>
                    <form >
                        <InputForm input="E-Mail" type="email" 
                            inputData={(Data => setUserData({...UserData, username: Data}))} 
                            additionalClassNames="first-obj"
                        />
                        <InputForm input="Password" type="password" 
                            inputData={(Data => setUserData({...UserData, password: Data}))} 
                            additionalClassNames={LoginDataFalse && "info-bottom"}
                        />
                        {LoginDataFalse && <p className='information-bottom'>User does not exist</p>}
                        <input type="button" className="submitButton" value="Log in" onClick={e => {
                            if (UserData['username'] !== "" && UserData['password'] !== ""){submitHandler(e)}
                        } 
                        }/>
                        <p>No Account yet? <Link to="/register" className='direct-link'>Sign up</Link></p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
