import React from 'react'
import '../style/css/horizontalArticle.css'
import {Link} from 'react-router-dom'

const horizontalArticle = ({Data}) => {

	// inefficent code to get time in ago
	const DateNow = Math.round(new Date().getTime() / 1000);
	const DateDifference =  DateNow - Data.content.time;
	var timeAgo = 0;
	if (DateDifference < 50){timeAgo = `${DateDifference}s`}
	else if (DateDifference < 3600){timeAgo = `${Math.round(DateDifference / 60)}min`}
	else if (DateDifference < 86400){timeAgo = `${Math.round(DateDifference / (60 * 60))}h`}
	else if (DateDifference < 604800){timeAgo = `${Math.round(DateDifference / (60 * 60 * 24))}d`}
	else if (DateDifference < 2419200){timeAgo = `${Math.round(DateDifference / (60 * 60 * 27 * 7))}w`}
	else if (DateDifference < 31104000){timeAgo = `${Math.round(DateDifference / (60 * 60 * 24 * 30))} ${Math.round(DateDifference / (60 * 60 * 24 * 30)) > 1 ? 'months' : 'month'}`}
	else {timeAgo = `${Math.round(DateDifference / (60 * 60 * 24 * 365))} years`}


    return (
        <Link to={`/user/${Data.author.username}/${Data.id}` } className="article">
            <img alt="thumbnail" src={Data.thumbnail} className="thumbnail" />
			<div className="infos">
				<div className="infos-centered">
					{Data.categories.map(x => (
						<Link to={`/category/${x}`} className="category" key={x.id}>{x}</Link>
					))}
					<br></br>
					<h4 className="heading">{Data.title}</h4>	
					<div className="article-info">
						<Link to={Data.author.url} >
							<img className="profil-picture" alt="profil picture" src={Data.author.pb} />
							<p className="username">{Data.author.username}</p>
						</Link>
						<p className="time">{timeAgo} ago</p>
						{/*<p className="impressions">{Data.impressions > 1000 ? (Data.impressions > 1000000 ? (Data.impressions / 1000000 + ' Mio') : (Data.impressions / 1000 + 'k')) : Data.impressions } Views</p>	*/}					
					</div>
					<p className="article-preview">{Data.content.blocks[0].data.text}</p>
				</div>
			</div>
        </Link>
    )
}

export default horizontalArticle
