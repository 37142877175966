import React from 'react'
import {Link} from 'react-router-dom'


const verticalArticle = ({Data}) => {

	const DateNow = Math.round(new Date().getTime() / 1000);
	const DateDifference =  DateNow - Data.content.time;
	console.log(DateDifference)
	var timeAgo = 0;
	if (DateDifference < 50){timeAgo = `${DateDifference}s`}
	else if (DateDifference < 3600){timeAgo = `${Math.round(DateDifference / 60)}min`}
	else if (DateDifference < 86400){timeAgo = `${Math.round(DateDifference / (60 * 60))}h`}
	else if (DateDifference < 604800){timeAgo = `${Math.round(DateDifference / (60 * 60 * 24))}d`}
	else if (DateDifference < 2419200){timeAgo = `${Math.round(DateDifference / (60 * 60 * 27 * 7))}w`}
	else if (DateDifference < 31104000){timeAgo = `${Math.round(DateDifference / (60 * 60 * 24 * 30))} ${Math.round(DateDifference / (60 * 60 * 24 * 30)) > 1 ? 'months' : 'month'}`}
	else {timeAgo = `${Math.round(DateDifference / (60 * 60 * 24 * 365))} years`}

    return (
        <Link className="articleView" to={``}>
			<img alt="thumbnail"src={Data.thumbnail} class="thumbnail" />
			<div className="article-info">
				<div className="article-categories">
					{Data.categories.map(x => (
					<Link to={`/category/${x}`} className="category" key={x.id}>{x}</Link>
					))}
				</div>
				<h4 className="title">{Data.title}</h4> 
				<div className="infos">
                       <Link className="author-link" to={Data.author.url}>
                           <img alt="profile-picture" className="pb" src={Data.author.pb} />
                           <p className="username">{Data.author.username}</p>
                       </Link>
                    <p className="time">{timeAgo} ago</p>
					{/*<p className="impressions">{Data.impressions > 1000 ? (Data.impressions > 1000000 ? (Data.impressions / 1000000 + ' Mio') : (Data.impressions / 1000 + 'k')) : Data.impressions } impressions</p>*/}
					</div>
				<p className="preview">{Data.content.blocks[0].data.text}</p>
			<div className="placeholder"></div>
			</div>
        </Link>
    )
}

export default verticalArticle
