import React, {useState} from 'react'
import ImageDropzone, {Cropp} from './imageDropzone'

const UploadAndCropImg = ({closeUploadImage, imgURL, cropRatio}) => {
    const [windowBigger, setWindowBigger] = useState(false)
    const [img, setImg] = useState(null)

    return (
        <div className={`form-group ${windowBigger && 'bigger-window'}`}>
            <div className="header">
                <a href="#" onClick={e => closeUploadImage()}><i class="lni lni-close close-icon"></i></a>
            </div>

            {(img == null) ? 
                <ImageDropzone img={imgURLData => {
                    setImg(imgURLData.toString())
                    setWindowBigger(true)
                }}/> : 
                <Cropp src={img} cropURL={e => imgURL(e)} aspect={cropRatio} />
            }
        </div>
    )
}

export default UploadAndCropImg
