import { Input } from '@material-ui/core'
import React from 'react'

const InputForm = ({ input, type, inputData, additionalClassNames, required }) => {

    return (
        <div className={`text-field text-${additionalClassNames}`}>
            { required ? 
                <input 
                type={type} 
                name="Username" 
                className={`input-group input-${additionalClassNames}`} 
                onChange={inputDataValue => inputData(inputDataValue.target.value)} 
                required
                /> : 
                <input 
                type={type} 
                name="Username" 
                className={`input-group ${additionalClassNames}`} 
                onChange={inputDataValue => inputData(inputDataValue.target.value)} 
                />
            }
            <label className="label">{ input }</label>
        </div>
    )
}

InputForm.defaultProps = {
    required: true,
    additionalClassNames: ""
}

export default InputForm
