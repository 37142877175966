import React, { useState, useEffect, Component } from 'react'
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom'
import 'react-image-crop/dist/ReactCrop.css';
import StandardProfilePic from '../icons/StandardProfilePic.png'
import InputForm from '../components/input-form';
import WrireLogo from '../icons/logo.svg'
import UploadAndCropImg from '../components/UploadAndCropImg';

import '../style/css/register-login.css'
import '../style/css/dropzone-cropper.css'


const Register = () => {
    const [confirmEmail, setConfirmEmail] = useState(false)
    const [UserData, setUserData] = useState({username: '', email: '', password: '', bio: '', name:'', image:{StandardProfilePic}})
    const [usernameExists, setUsernameExists] = useState(false)
    const [emailExists, setEmailExists] = useState(false)
    const [uploadImage, setUploadImage] = useState(false)


    const submitHandler = e => {
        setUsernameExists(false)
        setEmailExists(false)

        const data = new URLSearchParams(UserData).toString() // .json => string
        console.log(data)
        fetch('http://localhost:8000/api/register', { // fetch data from backend server
            method: 'POST',
            headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: data 
        }).then((response) =>  response.json().then((userToken)=>{
            const UserTokenString = new URLSearchParams(userToken).toString()

            if (emailExists == false && usernameExists == false){
                Cookies.set('UserToken', userToken["token"])
                setConfirmEmail(true)
            } 
        }))
    }

    useEffect((e) => {
        if (confirmEmail == true) {
            submitHandler(e)
        }
    }, [confirmEmail])


    return (
        <div className="register-side">
            <div className={`register-form ${uploadImage && "darken"} ${confirmEmail && 'bigger-place'}`}>
                <div className="container-center">
                    <img src={WrireLogo} className="wrire-logo" />
                    
                    {!confirmEmail ? 
                        (<div className="form-inputs">

                            {(UserData['username'] === '' && UserData['email'] === '' && UserData['email'] === '') ? 
                            <UserInfo 
                            submitUserData={data=>{console.log(data); setUserData({...UserData, username: data['username'], password: data['password'], email: data['email']});}}
                            />  :
                            <ProfileInfo 
                                uploadImage={x => setUploadImage(x)} 
                                test={UserData}
                                profilePicture={UserData['image']}
                                submitProfileData={data => {
                                    setConfirmEmail(true)
                                    setUserData({
                                        ...UserData, 
                                        bio: data['bio'], 
                                        name: data['name']
                                    })
                                }}
                                goBackToUserInfo={e => setUserData({username: '', email: '', password: '', bio: '', name:''})}
                            />
                            }
                            

                        </div>)
                    :
                    <div className="confirm-Email">
                        <h2>Fast geschafft</h2>
                        <h2>Bestätige deine E-Mail</h2>
                    </div>
                    }
                </div>

                
            </div>
            {uploadImage &&
            <UploadAndCropImg closeUploadImage={e => setUploadImage(false)} 
                imgURL={ProfileInfoURL => {
                    setUserData({...UserData, image: ProfileInfoURL})
                    console.log(UserData);
                    setUploadImage(false)}
                } 
                cropRatio={1}
            />
            }
        </div>
       
    )
}

const UserInfo = ({submitUserData}) => {
    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const [emailExists, setEmailExists] = useState(false)
    const [usernameExists, setUsernameExists] = useState(false)

    const checkUserAndEmail = (e) => {
        e.preventDefault()

        if (username != null && email != null && password != null) {
            fetch(`http://localhost:8000/api/check/${username}/${email}`, { // fetch data from backend server
                method: 'POST',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                } 
            }).then((response) => response.text().then(text => {
                if (text == "Okay"){submitUserData({username, email, password})}
                else if (text == "email"){setEmailExists(true); setUsernameExists(false)}
                else if (text == "username"){setUsernameExists(true); setEmailExists(false)}
                else if (text == "username"){setUsernameExists(true); setEmailExists(true)}
            }))
        }
        else{console.log(username, email, password)}
    }

    return(
        <form>
            <h1>Sign up</h1>

            <InputForm input="Username" type="text" inputData={(Data => setUsername(Data))} additionalClassNames="first-obj" />
            <InputForm input="E-Mail" type="email" inputData={(Data => setEmail(Data))}  />
            <InputForm input="Password" type="password" 
            inputData={(Data => setPassword(Data))} 
            additionalClassNames={(emailExists || usernameExists) && "info-bottom"} /> 

            {usernameExists && <p className="information-bottom">Username already exists</p>}
            {emailExists && <p className="information-bottom">E-Mail already exists</p>}

            <button className="submitButton" 
            onClick={(e) => checkUserAndEmail(e)} >Continue
            </button>

            <p>Already have an Account? <Link to="/login" className='direct-link'>Log in</Link></p>
        </form>
    )
}




const ProfileInfo = ({uploadImage, profilePicture, submitProfileData, goBackToUserInfo}) => {
    console.log(profilePicture)
    const [bio, setBio] = useState(null)
    const [name, setName] = useState(null)
    
    return(
        <form>
            <a onClick={() => goBackToUserInfo()} href="#"><i class="fas fa-caret-left caret-left"></i></a>
            <h1>Your Profile</h1>
            <div className="profile-picture-preview">
                <img src={profilePicture['StandardProfilePic']} style={{borderRadius: "40px"}} />
                <a href="#" onClick={x => uploadImage(true)}><i className="fas fa-camera camera-icon" ></i></a>
            </div>
            
            <InputForm input="Name" type="text" inputData={(Data => setName(Data))} />
            <div className="text-field text-bio">
                <textarea type="text" className="textarea-bio" onChange={bioData => {setBio(bioData.target.value)}} />
                <label className="label label-bio" >Bio (optional)</label>
            </div>

            <button className="submitButton" onClick={() => {          
            if(name != null){submitProfileData({bio, name})}
            }} >
                Sign up
            </button>

            <p>No Account yet? <Link to="/login" className='direct-link'>Log in</Link></p>
        </form>
    )
}
export default Register