import React, { useState, useContext } from 'react'
import '../style/css/nav_side_bar.css'
import WrireLogo from '../icons/logo.svg'
import WrireLogo1 from '../icons/logo1.svg'
import ExploreIcon from '../icons/explore-icon.svg'
import LogoIcon from '../icons/logo-icon.svg'
import LineIcon from "react-lineicons"
import Dropdown from "./dropdown"
import { ReactComponent as Caret } from "../icons/caret.svg"
import { Link } from 'react-router-dom'
import UserContext from '../UserContext'

import Cookies from 'js-cookie'

const Header = () => {
    const {user, setUser} = useContext(UserContext)
    const [open, setOpen] = useState(false);


    const logout = () => {
        console.log(logout)
        setUser(null)
        Cookies.remove("UserToken")
        setOpen(false)
    }
    return (
        <header className="Header">
            <div className="left-icons">
                <Link to="/home" className="logo">
                    <img className="logo-img" src={WrireLogo} alt="wrire-logo" />
                    <img className="logo-font" src={WrireLogo1} alt="wrire-logo" />
                </Link>
            </div>
            <div className="search-bar">
                <form method="get" className="search-bar-form">
                    <button type="submit" className="search-btn">
                        <LineIcon name="search" />
                    </button>
                    <input type="text" className="search" name="search" placeholder="Search" />
                </form>
            </div>
            
            <a href="/" className="infos-right">
                

                <Link to="/write" className="exploreButton">
                    <img className="explore-icon" src={LogoIcon} alt="write"/>
                </Link> 

                <Link to="/explore" className="exploreButton">
                    <img className="explore-icon" src={ExploreIcon} alt="write"/>
                </Link>

                
                {user ? // switch between sign in and profile btn
                <a className="profile" href="#" onClick={() => setOpen(!open)}>
                    <img className="header-profile-picture" src={user.avatar_url} />
                    <p>{user.username}</p>
                    <Caret className="caret"/>
                </a>
                :
                <Link to="/register" class="Sign-in"> 
                    <h3>Sign in</h3>
                </Link>}
                
            </a>
            { open && <Dropdown closeDropdown={() => console.log("Test")} logout={() => logout()} /> }
        </header>
        
    )
}

export default Header
