import React, {useState} from 'react'
import Header from '../components/header'
import Article from '../components/horizontalArticle'
import '../style/css/horizontalArticle.css'
import '../style/css/user.css'


const User = ({ match }) => {
    const [articles, setArticles] = useState([
        {
            id:1, 
            title: 'someTitle', 
            thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
            impressions: 3000000,
            content: {
                time: 1580296162, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {'style': 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['Science', 'Education', 'Tech', 'Test', 'Test'], 
            author: {
                username: 'someUsername', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:2, 
            title: 'someTitle', 
            thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
            impressions: 3000000,
            content: {
                time: 1580296162, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['6', '1'], 
            author: {
                username: 'someUsername', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:3, 
            title: 'someTitle', 
            thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
            impressions: 3000000,
            content: {
                time: 1580296162, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['6', '1'], 
            author: {
                username: 'someUsername', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:4, 
            title: 'someTitle', 
            thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
            impressions: 3000000,
            content: {
                time: 1580296162, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['6', '1'], 
            author: {
                username: 'someUsername', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:5, 
            title: 'someTitle', 
            thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
            impressions: 3000000,
            content: {
                time: 1580296162, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['6', '1'], 
            author: {
                username: 'someUsername', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        ])

    return (
        <>
            <Header />
            <div className="space">
                <div className="content">
                    <UserInfo name={match.params.id} />
                    {articles.map(article => (
                        <Article key={article.id} Data={article} />
                    ))}
                </div>
            </div>
        </>
    )
}

const UserInfo = ({name}) => {
    return(
        <div className='user-info-container-container'>
            <div className="user-info-container">
                <div className="user-standard-info-container">
                    <img className="profile-picture" src="https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg" /> 
                    <div className="name-following-info">
                        <p className="username-name">{name}</p>
                        <p id='follower'><b>30k</b> Follower</p>
                        <p id='following'><b>102</b> Follwing</p>
                    </div>
                    <input type="button" className='follow-btn' value="Follow" />
                </div>+
                <div className="bio-links-container">
                    <p className="bio">Sint enim aliquip excepteur sit sit excepteur exercitation dolor cupidatat pariatur.Dolore id labore aliqua tempor occaecat consequat elit sit eiusmod aliquip ex commodo.Anim deserunt sit Lorem cupidatat nulla eu officia aute esse Lorem sint tempor.</p>
                    {/*<div className="links"></div>*/}
                    <div className="placeholder"></div>
                </div>
            </div>
        </div>
    )
}

export default User
