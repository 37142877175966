import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import UserContext from './UserContext.js' 

import './App.css';
import './style/css/bodystyle.css'

import Home     from './pages/home'
import Explore  from './pages/explore'
import Write    from './pages/write'
import ReadArticle from './pages/read-article'
import User     from './pages/user'
import Category from './pages/category'
import Register from './pages/register'
import Login from './pages/login.js';

import Cookies from 'js-cookie'


function App() {
  const [user, setUser] = useState(null)

  const handleUser = (userToken) => { // get all information of user
    const authData = "Token " + userToken
    console.log(authData)

    fetch('http://localhost:8000/api/me', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            "Authorization": authData
        },
        body: ""
        }).then((response) =>  response.json().then((text)=>{
          console.log(text)
          setUser({...text, Authorization: userToken});
    }))
  }

  useEffect(() => {
    if (Cookies.get("UserToken") != undefined){
      console.log("Test")
      handleUser(Cookies.get("UserToken"))
    }
  }, [])
  return (
    <Router>
        <Switch>
          <Redirect exact from="/" to="/home" />
          
          <UserContext.Provider value={{user, setUser}}>
            <Route path="/home" exact component={ Home } />
            <Route path="/register" exact component={ Register } />
            <Route path="/login" exact component={ Login } />
            <Route path="/explore" exact component={ Explore } />
            <Route path="/write" component={ Write } />
            <Route path="/user/:username/:id" exact component={ ReadArticle }/>
            <Route path="/user/:id" exact component={ User } />
            <Route path="/category/:category" exact component={ Category } />
            <Route path='/user/:id/feed' component={(id) => {window.location.href = `http://localhost:8000/user/${id.match.params.id}/feed`; return null;}}/>
          </UserContext.Provider>
        </Switch>
    </Router>
  );
}

export default App;
