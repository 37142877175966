import React, { useEffect, useState, useContext } from 'react'
import '../style/css/article-space.css'
import Header from '../components/header'
import TextEditor from '../components/editorJS' 
import '../style/css/horizontalArticle.css'
import '../style/css/write.css'
import '../style/css/dropzone-cropper.css'
import '../style/css/register-login.css'
import UploadAndCropImg from '../components/UploadAndCropImg'
import UserContext from '../UserContext'

const Write = () => {
    const [articleData, setArticleData] = useState([null, ""])
    const [publishWindow, setPublishWindow] = useState(false)
    
    return (
        <>
            <div className={`write-page ${publishWindow && "darken"}`}>
                <Header />

                <TextEditor 
        //            content={articles[0].content} 
                    submitTextData={textData => {
                        console.log(textData)
                        setArticleData(textData)
                        setPublishWindow(true)
                    }}
                    headingValue={articleData[1]}
                />

                
            </div>
            {publishWindow &&
                <PublishWindow 
                    closeWindow={() => setPublishWindow(false)} 
                    headingData={articleData[1]} 
                    changeHeading={d => {setArticleData([articleData[0], d])}}
                    textData={articleData[0]}
                />
            }
        </>
    )
}

const PublishWindow = ({closeWindow, headingData, textData, changeHeading}) => {
    const [articleData, setArticleData] = useState({title: headingData, categories: null, content: textData, thumbnail: null})
    const [showUploadWindow, setShowUploadWindow] = useState(false)
    const {user, setUser} = useContext(UserContext)


    useEffect(() => {
        setArticleData({...articleData, title: headingData})
    }, [])

    const handlePublish = () => {
        const dataString = new URLSearchParams(articleData).toString()
        const authData = "Token " + user['Authorization']

        console.log(authData)
        console.log(articleData)
        console.log(dataString)

        fetch('http://localhost:8000/api/write', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                "Authorization": authData
            },
            body: dataString
        }).then((response) => response.json().then(text => console.log(text)))
    }
    return(
        <>
            <div className={`publish-window ${(showUploadWindow && "darken-half")}`}>
                <a href="#" onClick={() => closeWindow()}><i class="lni lni-close close-icon"></i></a>
                <p className="heading">Publish</p>
                <div className="heading-container">
                    <p className="label" style={{float: "left"}} >Title</p>
                    <input value={articleData['title']} onChange={inputData => {
                        setArticleData({...articleData, title: inputData.target.data}) 
                        changeHeading(inputData.target.value)}
                        }
                        placeholder='Type your heading here...'
                        className='heading-input'
                    ></input>
                </div>
                <div className="thumbnail-container">
                    <p className="label">Upload your Thumbnail</p>

                    {!articleData['thumbnail'] ? <input type="submit" onClick={() => {setShowUploadWindow(true)}} value="Upload" className="uploadImg" /> 
                    :
                    <a href="#" onClick={() => setShowUploadWindow(true)}><img src={articleData['thumbnail']} className="thumbnail-preview" /></a>
                    }
                    
                </div>
                
                <InputTags submitTags={categories => setArticleData({...articleData, categories: categories})} />
                <input type="button" onClick={ () => handlePublish() } className="publish-pl-window-btn" value="Publish now" />
            </div>
            {
                showUploadWindow &&
                <UploadAndCropImg closeUploadImage={() => setShowUploadWindow(false)} 
                    imgURL={url => {setArticleData({...articleData, thumbnail: url}); setShowUploadWindow(false)}}
                    cropRatio={1.77777777778} 
                />
                
            }
            
        </>
    )
}

const InputTags = ({submitTags}) => {
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [isKeyReleased, setIsKeyReleased] = useState(false);

    useEffect(() => {
        var stringTags = ""
        for (var i = 0; i < tags.length; i++) {
            stringTags = stringTags + tags[i] + ","
        }
        console.log(stringTags)
        submitTags(stringTags)
    }, [tags])

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const deleteTag = (index) => {
        setTags(prevState => prevState.filter((tag, i) => i !== index))
    }
      

    const onKeyDown = (e) => {
        const key = e['code'];
        const trimmedInput = input.trim();
      
        if ((key == 'Comma' && trimmedInput.length && !tags.includes(trimmedInput)) || (key == "Space" && trimmedInput.length && !tags.includes(trimmedInput))) {
          console.log("test")
          e.preventDefault();
          setTags(prevState => [...prevState, trimmedInput]);
          setInput('');
        }
      
        if ((key === "Backspace" && !input.length && tags.length && isKeyReleased)) {
          const tagsCopy = [...tags];
          const poppedTag = tagsCopy.pop();
          e.preventDefault();
          setTags(tagsCopy);
          setInput(poppedTag);
        }
      
        setIsKeyReleased(false);
      };
      
      const onKeyUp = () => {
        setIsKeyReleased(true);
    }
      
      
    return(
        <>
            
            <div className='tag-container'>
                <p className="tags-label">Tags: </p>
                {tags.map((tag, index) => (
                <div className="tag">
                    <p>{tag}</p>
                    <a href="#" onClick={() => deleteTag(index)}><i class="lni lni-close delete-tag"></i></a>
                </div>
                ))}
                {(tags.length < 3) && 
                <>
                    <input
                    value={input}
                    placeholder="Enter a tag"
                    onKeyDown={(e) => {onKeyDown(e)}}
                    onKeyUp={(e) => onKeyUp(e)}
                    onChange={(e) => onChange(e)}
                    />
                    
                </>
                }
            </div>
        </>
    )
}

export default Write
 