import React from 'react'
import {Link } from 'react-router-dom'

const categoryButton = ({ Data }) => {
    return (
        <Link className="Category-Button" to={`/category/${Data.toLowerCase()}`}>
            <p>{Data}</p>
        </Link>
    )
}

export default categoryButton
