import React, {useContext, useEffect, useState} from 'react'
import EditorJs from 'react-editor-js';
import { EDITOR_JS_TOOLS } from '../settings/editorjs-tools'
import edjsHTML from 'editorjs-html'
import '../style/css/article-space.css'
import UserContext from '../UserContext'


const TextEditor = ({ content, submitTextData, headingValue }) => {
    const instanceRef = React.useRef(null)
    const [heading, setHeading] = useState(headingValue)

    useEffect(() => {
        setHeading(headingValue)
    }, [headingValue])

    async function handleSave(){
        const savedData = await instanceRef.current.save();
        const edjsParser = edjsHTML(); 
        const html = edjsParser.parse(savedData);

        submitTextData([JSON.stringify(savedData), heading])
    }
    return (
        <div className="space">
            <div className="content">
                <input type="button" onClick={handleSave} className="publish-btn" value="Publish" />
                <input 
                type="text" 
                value={heading}
                class="heading-form" 
                placeholder="Heading" 
                onChange={inputDataValue => setHeading(inputDataValue.target.value)} 
                />
                <EditorJs instanceRef={(instance) => (instanceRef.current = instance)} 
                className="editorJS" 
                tools={EDITOR_JS_TOOLS} 
                data={content}
                />
            </div>
        </div>
    )
}

export default TextEditor