import React from 'react'
import VerticalArticle from './verticalArticle'

const verticalArticles = ({data}) => {
    return (
        <>
            <p className="heading">{data.heading}</p>
            <div class="content-flex">
                {data.articles.map(Articles => (
                    <VerticalArticle key={Articles.id} Data={Articles} />
                ))}
            </div>
        </>
    )
}

export default verticalArticles
