import React from 'react'
import HorizontalArticle from './horizontalArticle'

function HomeArticles({ article }) {
    return (
        <div className="space">
            <div className="content">
                {article.map(article => (
                    <HorizontalArticle key={article.id} Data={article} />
                ))}
            </div>
        </div>
    )
}

export default HomeArticles
