import React from 'react'
import Header from '../components/header'
import '../style/css/horizontalArticle.css'


const ReadArticle = ({ match }) => {
    console.log(match)
    return (
        <>
            <Header />
            <div class="space">
                <p >{match.params.category}</p>
            </div>
        </>
    )
}

export default ReadArticle
