import React, {useCallback, useState, useRef, Component} from 'react';
import Dropzone, {useDropzone} from 'react-dropzone';
import Cropper from 'react-easy-crop';

import Button from '@material-ui/core/Button'
import getCroppedImg from '../settings/cropImage.js'

import '../style/css/dropzone-cropper.css'
import { generateDownload } from '../settings/cropImage.js';


const ImageDropzone = ({img}) => {
    const [selectedFile, setSelectedFile] = useState(null)

    return (
        <>
            <Dropzone onDrop={files => {
                console.log(files)
                img(URL.createObjectURL(files['0']))
            }}>
              {({getRootProps, getInputProps}) => (
                  <div className="DragNDrop-Container">
                      <div {...getRootProps({className: 'dropzone', onDrop: event => event.stopPropagation()})}>
                          <input {...getInputProps()} />
                          <p>Drag 'n' drop some files here, or click to select files</p> 
                      </div>
                  </div>
              )}
            </Dropzone>
        </>
    )
}

export const Cropp = ({src, cropURL, aspect}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedArea, setCroppedArea] = useState(null)
    const [canvas, setCanvas] = useState(null)
    console.log("cropRatio: " + aspect)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels)
    }, [])

    const handleConfirm = () => {
        getCroppedImg(src, croppedArea).then(e => {cropURL(e)})
    }

    return (
      <div className="crop-container">
        <div className="img-container">
          <Cropper
            image={src}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <input
          className="img-slider"
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value)
          }}
        />
        <div className='confirm-btn-div'>
          <button onClick={handleConfirm} className='confirm-btn'>Confirm</button>
        </div>
        
      </div>
    )
}


export default ImageDropzone;