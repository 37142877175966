import React, { useState } from 'react'
import '../style/css/article-space.css'
import '../style/css/explore.css'
import Header from '../components/header'
import Categories from '../components/categorySuggestions'
import VerticalArticles from '../components/verticalArticles'
import HorizontalArticle from '../components/horizontalArticle'


const Explore = () => {
    const [categories, setCategories] = useState(["Science", "Rockets", "Discussion", "Productivity", "Programming", "Economics", "Tipps & Tricks", "Politics", "Writing", "Education", "Tech", "Space", "Popular", "New", "Biology", "Nature", "Art", "Science", "News", "Investments", "Stocks", "History"])
    const [verticalArticlesData, setverticalArticlesData] = useState([[{
        id: 0,
        heading: "Politics",
        articles: [{
            id:1, 
            title: 'Wie wird Corona enden? Lagebericht Corona', 
            thumbnail: 'https://cdn.pixabay.com/photo/2020/04/24/11/09/coronavirus-5086447_1280.jpg',
            impressions: 3000000,
            content: {
                time: 1638471041, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {'style': 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['Corona', 'Virus', 'Pandemic'], 
            author: {
                username: 'Jonas', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:2, 
            title: 'Können wir den Klimawandel überhaupt noch aufhalten?', 
            thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916070916423831552/landscape-g8f96e519e_1920_2.jpg',
            impressions: 3000000,
            content: {
                time: 1627967534, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['Climate Change', 'Environment'], 
            author: {
                username: 'Tim', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:3, 
            title: 'Olaf Scholz, wer ist der neue Bundeskanzler von Deutschland?', 
            thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916071466817159208/olaf-scholz-g35dd38a7d_1920_1.jpg',
            impressions: 3000000,
            content: {
                time: 1633237934, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['Germany', 'Government', 'Politics'], 
            author: {
                username: 'RRD', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:4, 
            title: 'Das Bildungssystem muss reformiert werden - und zwar dringend.', 
            thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916070007404896358/Education.jpg',
            impressions: 3000000,
            content: {
                time: 1638339134, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['Education', 'School'], 
            author: {
                username: 'Max R.', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:5, 
            title: 'someTitle', 
            thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
            impressions: 3000000,
            content: {
                time: 1635747134, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['6', '1'], 
            author: {
                username: '', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        }]},
        {
            id: 2,
            heading: "Rising",
            articles: [{
                id:1, 
                title: '10 Dinge die DU beim kauf von Aktien beachten solltest', 
                thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916069931211182130/Stock.jpg',
                impressions: 3000000,
                content: {
                    time: 1638504734, 
                    blocks: [{
                        id: 'YdVFd9yMae', 
                        type: 'paragraph', 
                        data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                    }, {
                        id: 'gVuSPsEn74', 
                        type: 'ad', 
                        data: {'style': 'banner'}
                    }], 
                    version: '2.22.0'
                }, 
                categories: ['Stocks', 'Market'], 
                author: {
                    username: 'CryptoTrader', 
                    pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                    url: '/User/1', 
                    likes: 1, 
                    client_liked: true
                }
            },
            {
                id:2, 
                title: 'How to center a div', 
                thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916075749566464020/code-g17309d889_1920.jpg',
                impressions: 3000000,
                content: {
                    time: 1627960334, 
                    blocks: [{
                        id: 'YdVFd9yMae', 
                        type: 'paragraph', 
                        data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                    }, {
                        id: 'gVuSPsEn74', 
                        type: 'ad', 
                        data: {style: 'banner'}
                    }], 
                    version: '2.22.0'
                }, 
                categories: ['HTML', 'Coding', 'CSS'], 
                author: {
                    username: 'Start Coding', 
                    pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                    url: '/api/User/1', 
                    likes: 1, 
                    client_liked: true
                }
            },
            {
                id:3, 
                title: 'Neue Durchbrüche in der Gentechnik', 
                thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916077363362672720/DNA.jpg',
                impressions: 3000000,
                content: {
                    time: 1628219534, 
                    blocks: [{
                        id: 'YdVFd9yMae', 
                        type: 'paragraph', 
                        data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                    }, {
                        id: 'gVuSPsEn74', 
                        type: 'ad', 
                        data: {style: 'banner'}
                    }], 
                    version: '2.22.0'
                }, 
                categories: ['Biology', 'Genfood'], 
                author: {
                    username: 'Tim', 
                    pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                    url: '/api/User/1', 
                    likes: 1, 
                    client_liked: true
                }
            },
            {
                id:4, 
                title: 'Ist vegetarische / vegane Ernährung sinnvoll?', 
                thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916069931739656222/Vegetables.jpg',
                impressions: 3000000,
                content: {
                    time: 1638493934, 
                    blocks: [{
                        id: 'YdVFd9yMae', 
                        type: 'paragraph', 
                        data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                    }, {
                        id: 'gVuSPsEn74', 
                        type: 'ad', 
                        data: {style: 'banner'}
                    }], 
                    version: '2.22.0'
                }, 
                categories: ['Vegetarian', 'Meat', "Vegtables"], 
                author: {
                    username: 'Tim', 
                    pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                    url: '/api/User/1', 
                    likes: 1, 
                    client_liked: true
                }
            },
            {
                id:5, 
                title: 'someTitle', 
                thumbnail: 'https://images.unsplash.com/photo-1601397213627-12bbfd095a0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2978&q=80',
                impressions: 3000000,
                content: {
                    time: 1638471041, 
                    blocks: [{
                        id: 'YdVFd9yMae', 
                        type: 'paragraph', 
                        data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                    }, {
                        id: 'gVuSPsEn74', 
                        type: 'ad', 
                        data: {style: 'banner'}
                    }], 
                    version: '2.22.0'
                }, 
                categories: ['6', '1'], 
                author: {
                    username: 'RocketS', 
                    pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                    url: '/api/User/1', 
                    likes: 1, 
                    client_liked: true
                }
            }]}
        ], [{
            id:1, 
            title: 'Warum immer mehr private Raumfahrtunternehmen gibt', 
            thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916069931001470986/Rocket_Launch.jpg',
            impressions: 3000000,
            content: {
                time: 1638321134, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {'style': 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['Rocket Science', 'Rockets', "SpaceX", "NASA", "Blue Origin"], 
            author: {
                username: 'RocketStuff', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/User/1', 
                likes: 1, 
                client_liked: true
            }
        },
        {
            id:2, 
            title: 'Hohe Inflation in Deutschland - man muss besorgt sein', 
            thumbnail: 'https://media.discordapp.net/attachments/804256706514649159/916070007643975721/Euro2.jpg',
            impressions: 3000000,
            content: {
                time: 1638407534, 
                blocks: [{
                    id: 'YdVFd9yMae', 
                    type: 'paragraph', 
                    data: {text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
                }, {
                    id: 'gVuSPsEn74', 
                    type: 'ad', 
                    data: {style: 'banner'}
                }], 
                version: '2.22.0'
            }, 
            categories: ['EZB', 'Euro', "Europe", "Germany"], 
            author: {
                username: 'MAZ', 
                pb: 'https://png.pngitem.com/pimgs/s/35-350426_profile-icon-png-default-profile-picture-png-transparent.png',
                url: '/api/User/1', 
                likes: 1, 
                client_liked: true
            }
        },]])
    return (
        <>
            <Header />
            <div className="space">
                <div className="content">
                    <p className="heading">Categories</p>
                    <Categories Categories={ categories }/>

                    {verticalArticlesData[0].map(Data => (
                        <VerticalArticles key={Data.id} data={Data} />
                    ))}
                    
                    <p className="heading">Popular</p>
                    {verticalArticlesData[1].map(Data => (
                        <HorizontalArticle key={Data.id} Data={Data} />
                    ))}
                </div>
            </div>
            
        </>
    )
}

export default Explore
