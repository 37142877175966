// tools.js
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
import Warning from '@editorjs/warning'
import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
import ImageTool from '@editorjs/image'
import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import Cookie from 'js-cookie'

class AdBlock {
  static get toolbox() {

    return {
      title: 'Advert',
      icon: '<svg width="15px" height="15px" version="1.1" id="lni_lni-dollar" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve"> <path stroke-width="5" d="M39.7,30.3H24.3c-5.4,0-9.9-4.2-9.9-9.4s4.4-9.4,9.9-9.4h22.9c1,0,1.8-0.8,1.8-1.8S48.1,8,47.1,8H35.8V3 c0-1-0.8-1.8-1.8-1.8S32.3,2,32.3,3v5h-8.1c-7.4,0-13.4,5.8-13.4,12.9c0,7.1,6,12.9,13.4,12.9h15.5c5.4,0,9.9,4.2,9.9,9.4 s-4.4,9.4-9.9,9.4H13.6c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h18.7v5c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8v-5h3.9 c7.4,0,13.4-5.8,13.4-12.9S47.1,30.3,39.7,30.3z"/> </svg>'
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({data, readOnly}){
    this.wrapper = undefined;
    this.readOnly = readOnly;
    this.data = data;
    this.adStyle = data["style"]
    if (this.adStyle === undefined) {
      this.adStyle = "banner"
    }
    this.settings = [
      {
        name: 'small',
        icon: '<svg width="25px" height="25px" viewBox="0 0 1080 1080" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"> <!-- Generated by Pixelmator Pro Demo 2.0.6 --> <path id="Abgerundetes-Rechteck" d="M365 325 C342.909 325 325 342.909 325 365 L325 715 C325 737.091 342.909 755 365 755 L715 755 C737.091 755 755 737.091 755 715 L755 365 C755 342.909 737.091 325 715 325 Z" fill="none" stroke="#000000" stroke-width="50" stroke-opacity="1" stroke-linejoin="round" stroke-linecap="round"/> </svg>'
      },
      {
        name: 'banner',
        icon: '<svg width="25px" height="25px" viewBox="0 0 1080 1080" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"> <!-- Generated by Pixelmator Pro Demo 2.0.6 --> <path id="Abgerundetes-Rechteck" d="M155 375 C132.909 375 115 392.909 115 415 L115 665 C115 687.091 132.909 705 155 705 L925 705 C947.091 705 965 687.091 965 665 L965 415 C965 392.909 947.091 375 925 375 Z" fill="none" stroke="#000000" stroke-width="50" stroke-opacity="1" stroke-linejoin="round" stroke-linecap="round"/> </svg>'
      },
      {
        name: 'large',
        icon: '<svg width="25px" height="25px" viewBox="0 0 1080 1080" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"><path id="Abgerundetes-Rechteck" d="M155 115 C132.909 115 115 132.909 115 155 L115 925 C115 947.091 132.909 965 155 965 L925 965 C947.091 965 965 947.091 965 925 L965 155 C965 132.909 947.091 115 925 115 Z" fill="none" stroke="#000000" stroke-width="50" stroke-opacity="1" stroke-linejoin="round" stroke-linecap="round"/> </svg>'
      }
    ];
  }


  render(){
    this.wrapper = document.createElement('div');
    const placeholdAd = document.createElement('div');
    placeholdAd.classList.add("advert")
    if (this.readOnly) {
      placeholdAd.style = "border: 5px solid #f44336; border-radius: 20px; text-align: center; vertical-align: middle; margin: 10px;"
      placeholdAd.innerText = "Here will be an Ad."
    } else {

      placeholdAd.style = "border: 5px solid #f44336; border-radius: 20px; text-align: center; vertical-align: middle; margin: 10px;";

      const p = document.createElement('p');
      p.innerText = "Here will be an Ad.";

      placeholdAd.appendChild(p);
      const a = document.createElement('a');
      a.href = "#"
      a.innerText = "Get more information about monetization and wether you are eligible here."
      placeholdAd.appendChild(a);
      placeholdAd.appendChild(document.createElement('br'));
      const buttonsmall = document.createElement('button');
      const buttonbanner = document.createElement('button');
      const buttonlarge = document.createElement('button');


      buttonsmall.innerText = "Small Advert";
      buttonbanner.innerText = "Banner Advert";
      buttonlarge.innerText = "Large Advert";

      buttonsmall.classList.add("adbtn")
      buttonbanner.classList.add("adbtn")
      buttonlarge.classList.add("adbtn")

      buttonsmall.addEventListener('click', (e) => {
        e.preventDefault();
        this.wrapper.children[0].classList.remove(this.adStyle);

        this.adStyle = "small";

        this.wrapper.children[0].classList.add(this.adStyle);
        this.wrapper.focus();

      });
      buttonbanner.addEventListener('click', (e) => {
        e.preventDefault();
        this.wrapper.children[0].classList.remove(this.adStyle);

        this.adStyle = "banner";

        this.wrapper.children[0].classList.add(this.adStyle);
        this.wrapper.focus();

      });
      buttonlarge.addEventListener('click', (e) => {
        e.preventDefault();
        this.wrapper.children[0].classList.remove(this.adStyle);

        this.adStyle = "large";

        this.wrapper.children[0].classList.add(this.adStyle);
        this.wrapper.focus();

      });
      placeholdAd.appendChild(buttonsmall)
      placeholdAd.appendChild(buttonbanner)
      placeholdAd.appendChild(buttonlarge)
    }
    placeholdAd.classList.add(this.adStyle)
    console.log(placeholdAd)
    this.wrapper.appendChild(placeholdAd);
    this.wrapper.focus();
    return this.wrapper


  }

  renderSettings(){

    const wrapper = document.createElement('div');

    this.settings.forEach( tune => {
      let button = document.createElement('div');

      button.classList.add('cdx-settings-button');
      button.innerHTML = tune.icon;
      wrapper.appendChild(button);
      button.addEventListener('click', () => {
        this.wrapper.children[0].classList.remove(this.adStyle);

        this.adStyle = tune.name;

        this.wrapper.children[0].classList.add(this.adStyle);

        this.wrapper.focus();

      });
    });

    return wrapper;
  }

  /**
   * @private
   * Click on the Settings Button
   * @param {string} tune — tune name from this.settings
   */


  save(blockContent){
    return {
      ad: blockContent.value,
      style: this.adStyle
    }
  }


}

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  paragraph: Paragraph,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: ImageTool,
    config: {
      endpoints: {
        byFile: 'http://localhost:8000/api/host', // Your backend file uploader endpoint
      },
      additionalRequestHeaders: {"Authorization": `Token ${Cookie.get("UserToken")}`}
    }
  },
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  advert: AdBlock,
}

